import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

const LatestPosts = () => {
    const data = useStaticQuery(graphql`
        query {
            allMdx(
                sort: {fields: frontmatter___pubDate, order: DESC}
                limit: 5
                filter: {frontmatter: {pubDate: {ne: null}}}
            ) {
                edges {
                node {
                    excerpt
                    fields {
                    slug
                    title
                    }
                    frontmatter {
                    pubDate
                    }
                }
                }
            }
        }
    `)

    const posts = data?.allMdx?.edges;
    return (
        <figure>
            {/* <figcaption><h2>Latest posts</h2></figcaption> */}
            <ul>
                {posts?.map(({ node }) => {
                    return (
                        <li key={node.fields.slug}>
                            <Link to={node.fields?.slug}>{node.fields?.title}</Link>
                            {/* <p>{node?.excerpt}</p> */}
                        </li>
                    );
                })}
            </ul>
        </figure>
    );
}

export default LatestPosts;