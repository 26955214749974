import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PillarComponent from './pillar'

export default function PillarPricing() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: frontmatter___pubDate, order: DESC }
        limit: 5
        filter: { frontmatter: { pillar: { eq: "Pricing" } } }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
              title
            }
            frontmatter {
              pubDate
            }
          }
        }
      }
    }
  `)

  const posts = data.allMdx.edges

  return <PillarComponent posts={posts} />
}
