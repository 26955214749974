import React from 'react'

const Optin = ({ optinHeader, optinBody }) => {
  return (
    <div>
      {!!optinHeader && !!optinBody ? (
        <>
          <h4>{optinHeader}</h4>
          <p>{optinBody}</p>
        </>
      ) : (
        <>
          <h4>So you're nice people, and you just want people to buy your work.</h4>
          <p>
            But how do you make that happen without having to be salesy or pushy?
          </p>
          <p>
            How do you convert buyers into customers, how do you stop getting ghosted, how do you get paid the money you deserve?{' '}
          </p>
          <p>
            Get answers, and more sales, with a free subscription to a short & helpful daily piece of advice.
          </p>
          {/* <h4>Tired of hagglers, stalled deals, and getting ghosted?</h4>
          <p>
            You're not alone: everyone who sells faces that. Subscribe for a
            short daily email, and get better at selling every day.{' '}
          </p>
          <p>
            Bonus: Instant download of the 📈 SFC Pipeline Habit Scorecard 👇{' '}
          </p> */}
          {/* <h4>Did that strike a chord?</h4>
                        <p>Articles like these get added to the Vault each day - drop your email to receive them in your inbox automatically....</p> */}
        </>
      )}
      <iframe
        title="Plain Optin"
        src="https://personal.salesflowcoach.app/mailerlite-form-plain_embeddable"
        style={{
          display: 'block',
          margin: '0 auto',
          width: '100%',
          height: '110px',
        }}
      />
    </div>
  )
}

export default Optin
