import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import LatestPosts from '../components/latest-posts'
import {
  PillarMessaging,
  PillarPitching,
  PillarPricing,
  PillarProblemStack,
  PillarSignals,
} from '../pillars'
import VideoEmbed from './video-embed'

const components = {
  LatestPosts,
  PillarMessaging,
  PillarPitching,
  PillarPricing,
  PillarProblemStack,
  PillarSignals,
  VideoEmbed
}

const CustomMDXRenderer = ({ children }) => {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}

export default CustomMDXRenderer
