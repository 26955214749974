import { graphql, Link } from 'gatsby'
import React from 'react'
// import { Graph } from 'react-d3-graph'
// import Graph from 'react-graph-vis'
import { Helmet } from 'react-helmet'
import CustomMDXRenderer from '../components/custom-mdx-renderer'
import Lyket from '../components/lyket'
import OptinForm from '../components/optin'
import Layout from '../layout/layout'
import '../styles/graph.css'
import * as notesStyles from '../styles/note-content.module.css'
import '../styles/note.css'
import { siteMetadata } from '../../gatsby-config'
const makeSlug = require('../utils/make-slug')

let titles = []
const makeId = title => {
  titles.push(title)
  return title
}

const nodeExists = title => {
  return titles.includes(title)
}

export default function Note({ pageContext, data, location }) {
  const post = data.mdx
  // const [toggle, setToggle] = React.useState({
  //   workflows: false,
  //   business: false,
  // })

  // const hideToggle = title => {
  //   setToggle({
  //     ...toggle,
  //     [title]: !toggle[title],
  //   })
  // }

  // Create the data for the graph visualisation for the note linking.
  const graph = {
    nodes: [{ id: makeId(post.fields.title), label: post.fields.title }],
    edges: [],
  }

  // Links to the current Note
  //for (let i = 0; i < pageContext.referredBy.length; i++) {
  //  const refNoteTitle = pageContext.referredBy[i].title
  //  if(!nodeExists(refNoteTitle)) graph.nodes.push({ id: makeId(refNoteTitle), label: refNoteTitle })
  //  graph.edges.push({ from: makeId(refNoteTitle), to: makeId(post.fields.title) })
  //}

  // Links from the current Note
  for (let i = 0; i < pageContext.refersTo.length; i++) {
    const refNoteTitle = pageContext.refersTo[i].split('#')[0]
    if (!nodeExists(refNoteTitle))
      graph.nodes.push({ id: makeId(refNoteTitle), label: refNoteTitle })
    graph.edges.push({
      from: makeId(post.fields.title),
      to: makeId(refNoteTitle),
    })
  }

  // const options = {
  //   autoResize: true,
  //   // layout: {
  //   //   randomSeed: 1,
  //   //   improvedLayout: true,
  //   //   hierarchical: {
  //   //     enabled: true,
  //   //     levelSeparation: 150,
  //   //     nodeSpacing: 200,
  //   //     treeSpacing: 200,
  //   //     sortMethod: "directed" // hubsize, directed
  //   //   }
  //   // },
  //   edges: {
  //     smooth: {
  //       forceDirection: 'none',
  //     },
  //   },
  //   nodes: {
  //     widthConstraint: 180,
  //     // shape: "image",
  //     shape: 'dot',
  //     size: 8,
  //     shapeProperties: {
  //       borderDashes: false, // only for borders
  //     },
  //     color: {
  //       border: '#aaa',
  //       background: '#aaa',
  //       highlight: {
  //         border: '#ddd',
  //         background: '#999',
  //       },
  //       hover: {
  //         border: '#ddd',
  //         background: '#999',
  //       },
  //     },
  //     borderWidth: 1,
  //     font: {
  //       color: '#000',
  //       size: 18,
  //     },
  //   },
  //   physics: {
  //     barnesHut: {
  //       springLength: 150,
  //     },
  //     enabled: true,
  //   },
  //   groups: {
  //     // selected: { color: { background: "#feff00" } },
  //     root: { color: { background: '#feff00' } },
  //     exit: { color: { background: 'feff00' } },
  //   },
  // }

  // const events = {
  //   select: function (event) {
  //     var { nodes } = event
  //     const id = nodes[0].toLowerCase()
  //     const node = pageContext.linkedNotes[id]
  //     navigate(`${node.slug}`)
  //   },
  // }

  // const renderNavigationMenu = (title, links) => {
  //   return (
  //     <>
  //       <div className="nav-menu">
  //         <h5>{title}</h5>
  //         <div className="toggle-icon" onClick={() => hideToggle(title)}>
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             viewBox="0 0 24 24"
  //             fill="currentColor"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
  //               clipRule="evenodd"
  //             />
  //           </svg>
  //         </div>
  //       </div>

  //       {links.map(item => {
  //         return (
  //           <li
  //             className={`${!toggle[title] && 'none'} active `}
  //             key={item.name}
  //           >
  //             <Link to={item.slug}>{item.name}</Link>
  //           </li>
  //         )
  //       })}
  //     </>
  //   )
  // }

  function renderOutgoingLinks() {
    return graph.nodes.slice(1).map((item, index) => {
      const node = pageContext.linkedNotes[item.id.toLowerCase()]?.slug
      return (
        <li key={item.id}>
          <Link to={node}>{item.label}</Link>
        </li>
      )
    })
  }

  const cleanPostBody = () => {
    const blockReferencePattern = /\^(\w+)/g

    let body = post.body.replace(blockReferencePattern, '')

    // Make the image point to root
    const fixImgUrls = /"src": "Media/g;
    return body.replace(fixImgUrls, '"src": "/Media')
  }

  // This is for putting a white background on the content of the notes and landing pages
  const columnStyle = {
    fontColor: '#54595F',
    backgroundColor: '#fafcff',
    fontSize: '14px',
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={siteMetadata.siteUrl + post.fields.slug + '/'}
        />
        <meta
          property="og:url"
          content={siteMetadata.siteUrl + post.fields.slug + '/'}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout
        Layout
        title={post.fields.title}
        type={!!post.frontmatter?.type ? post.frontmatter?.type : 'note'}
        meta={{
          description: post.frontmatter?.metaDescr,
          keywords: post.frontmatter?.keywords,
        }}
      >
        {
          // If type in frontmatter === Landing then show the no-frills landing page else show whole note template
          post.frontmatter?.type === 'Landing' ? (
            <>
              <main
                className="columms"
                style={{
                  ...columnStyle,
                  padding: '1rem',
                  backgroundColor: 'white',
                }}
              >
                <div className="landing" style={{ margin: '0 auto' }}>
                  <h1 className="note-title">{post.fields.title}</h1>
                  <div className={notesStyles.notes}>
                    <CustomMDXRenderer>{cleanPostBody()}</CustomMDXRenderer>
                  </div>
                </div>
              </main>
            </>
          ) : (
            <>
              <div className="column is-five-fifths note-page-section">
                <main className="columns">
                  {/* <div className="column is-one-fifth" style={columnStyle}>
                    <ul className="nav-list" style={{ paddingTop: '0.7em' }}>
                      {renderNavigationMenu('🔁 Workflows', [
                        {
                          slug: '/start-salesflow-coach',
                          name: '▶️ Start SalesFlow Coach',
                        },
                        {
                          slug: '/pick-a-lead-and-review-where-that-deal-is-at',
                          name: '▶️ Pipeline review',
                        },
                        {
                          slug: '/myno-mine-your-network-for-opportunities',
                          name: '▶️ Mine your network for opportunities',
                        },
                        {
                          slug: '/must-know-buyer-intel',
                          name: '▶️ Must-know buyer intel (The Empathy Edge)',
                        },
                        {
                          slug: '/cull-cleanup',
                          name: '▶️ Cull & clean up your pipeline',
                        },
                        {
                          slug: '/tags/workflows',
                          name: '▶️ All Workflows',
                        },
                      ])}

                      {renderNavigationMenu('🔎 Business review', [
                        {
                          slug: '/how-is-your-business-doing',
                          name: '❓ How is your business doing?',
                        },
                        {
                          slug: '/how-is-your-money-game',
                          name: '❓ How is your Money Game?',
                        },
                        {
                          slug: '/got-any-deals-in-your-pipeline',
                          name: '❓ Any deals in your pipeline?',
                        },
                      ])}
                    </ul>
                  </div> */}
                  <div className="landing">
                    <h1 className="note-title">{post.fields.title}</h1>
                    <div className={notesStyles.notes}>
                      <CustomMDXRenderer>{cleanPostBody()}</CustomMDXRenderer>
                    </div>
                    {(!!post.frontmatter?.lyket ||
                      !!post.frontmatter?.lyketHeader ||
                      post.frontmatter?.lyketBody ||
                      post.frontmatter?.lyketButton) && (
                      <Lyket
                        lyketHeader={post.frontmatter?.lyketHeader}
                        lyketBody={post.frontmatter?.lyketBody}
                        lyketButton={post.frontmatter?.lyketButton}
                        title={post.fields.title}
                      />
                    )}
                    <div className="note-meta" style={{ opacity: 0.7 }}>
                      {post.frontmatter.tags ? (
                        <div className="related block-area">
                          <div className="note-tags">
                            <strong className="note-meta-title">
                              <br />
                              Tags:{' '}
                            </strong>
                            <ul>
                              {post.frontmatter.tags.map((tag, index) => (
                                <li key={index}>
                                  <Link to={`/tags/${makeSlug(tag)}`}>
                                    {tag}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                      {graph.nodes.length > 0 && (
                        <div>
                          <div>
                            <strong className="note-meta-title">
                              <br />
                              Outgoing Links:{' '}
                            </strong>
                            <ul>{renderOutgoingLinks()}</ul>
                          </div>
                        </div>
                      )}
                    </div>
                    {
                      // Lyket properties must not be present in order to render this
                      !post.frontmatter?.lyket &&
                        !post.frontmatter?.lyketHeader &&
                        !post.frontmatter?.lyketBody &&
                        !post.frontmatter?.lyketButton &&
                        ((post.frontmatter?.tags?.includes('Articles') &&
                          post.frontmatter.optin !== false) ||
                          !!post.frontmatter.optinHeader ||
                          !!post.frontmatter.optinBody) && (
                          <>
                            <div
                              className="note-navigation columns"
                              style={{ fontSize: '14px' }}
                            ></div>
                            <div className="optin-form-container">
                              <OptinForm
                                optin={post.frontmatter?.optin}
                                optinHeader={post.frontmatter?.optinHeader}
                                optinBody={post.frontmatter?.optinBody}
                              />
                            </div>
                          </>
                        )
                    }

                    <div className="footer-content">
                      <div
                        className="note-navigation columns"
                        style={{ fontSize: '14px' }}
                      ></div>
                      <p
                        className="is-size-7"
                        style={{ fontSize: '9px', opacity: 0.7 }}
                      >
                        {' '}
                        SalesFlow Coach V 1.0 | Made by{' '}
                        <a href="https://martinstellar.com">
                          Martin Stellar
                        </a>{' '}
                      </p>
                      {/* <p
                        className="is-size-7"
                        style={{ fontSize: '9px', opacity: 0.7 }}
                      >
                        {' '}
                        SalesFlow Coach V 1.0 | Made by Martin Stellar |
                        Built with{' '}
                        <a href="https://github.com/binnyva/gatsby-garden/">
                          Gatsby Garden
                        </a>{' '}
                      </p> */}

                      {/* TODO: Adding vault here for now but must be changed when the notes doesn't live in the vault folder anymore */}
                      {/* <a href="/rss.xml" target="_blank" rel="noreferrer">
                        <StaticImage
                          height={20}
                          width={20}
                          src="../images/rss-icon.png"
                        />
                      </a> */}
                    </div>
                  </div>
                  {/* <div className="column is-one-and-a-half-fifths">
                    <br />

                    <div className="optin-daily">
                      <h4>
                        Want to get better at managing your pipeline and closing
                        your deals?
                      </h4>
                      <p>
                        Subscribe to receive a short & useful daily email, and
                        also:{' '}
                      </p>
                      <p>
                        Get instant access to the SFC Pipeline Habit Scorecard,
                        and get a diagnosis on where your sales process can be
                        improved 👇
                      </p>

                      <iframe
                        src="https://personal.salesflowcoach.app/mailerlite-form-plain_embeddable"
                        style={{
                          display: 'block',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      />
                    </div>

                    {!post.frontmatter?.tags?.includes('DevLog') ? (
                      <>
                        <h4>Navigation</h4>
                        <div className="note-graph">
                          <Graph
                            graph={graph}
                            options={options}
                            events={events}
                          />
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div> */}
                </main>
              </div>
            </>
          )
        }
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        title
        date
        slug
      }
      frontmatter {
        tags
        source
        type
        lyket
        lyketHeader
        lyketBody
        lyketButton
        optin
        optinHeader
        optinBody
        keywords
        metaDescr
      }
    }
  }
`
