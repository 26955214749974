import React from 'react';
import "../styles/component.css"

const VideoEmbed = ({ videoLink }) => {
    let videoId;
    if (videoLink.includes('youtube.com')) {
        const queryParams = new URLSearchParams(new URL(videoLink).search);
        videoId = queryParams.get('v');
    } else {
        videoId = videoLink.split('v=')[1];
    }

    const embedLink = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div className="responsive-video">
            <iframe title={ "Video: " + videoId } src={embedLink} frameborder="0" allowfullscreen></iframe>
        </div>
    );
};

export default VideoEmbed;
