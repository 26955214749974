import React from 'react'
import { LikeButton } from '@lyket/react'
const makeSlug = require('../utils/make-slug')

const Lyket = ({ lyketHeader, lyketBody, lyketButton, title }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="lyket-container">
      <div className={`overlay ${isOpen ? 'active' : ''}`}>
        <div className="modal">
          <button
            style={{
              position: 'absolute',
              right: '5px',
              top: '5px',
              color: 'white',
              backgroundColor: '#f7aca8',
              fontSize: '10px',
            }}
            onClick={() => setIsOpen(false)}
          >
            X
          </button>
          <iframe
            width="400"
            height="276"
            title="Mailerlite Optin"
            src="https://personal.salesflowcoach.app/mailerlite/"
          ></iframe>
        </div>
      </div>

      <LikeButton id={makeSlug(title)} namespace="post">
        {({
          handlePress,
          totalLikes,
          userLiked,
          isLoading,
          isCounterVisible,
        }) => {
          const handleClick = () => {
            setIsOpen(!isOpen)
          }

          return (
            <>
              {userLiked ? (
                <>
                  <p className="lyket-copy">
                    Thank you for your vote! 👍 Once 10 people vote, Martin will
                    then start building this page ⚒️
                  </p>
                </>
              ) : (
                <>
                  <div className="lyket-copy">
                    {
                      <h3 className="header">
                        {lyketHeader ??
                          'Want Martin to develop a full training on this topic?'}
                      </h3>
                    }
                    <p>
                      {lyketBody ??
                        'This is a seed-page: a short introduction to a fundamental topic. You can add your vote, for Martin to develop this into a workflow - just hit the Like button'}
                    </p>
                  </div>
                  <div
                    className={`like-button-container ${
                      userLiked ? 'active' : ''
                    }`}
                  >
                    <button
                      className={`like-button`}
                      onClick={e => {
                        handleClick()
                        handlePress(e)
                      }}
                      disabled={isLoading}
                    >
                      {lyketButton ||
                        'Once 10 people vote, Martin will start building this article'}{' '}
                      👍
                    </button>
                    {/*
                            // This shows the total votes
                            {isCounterVisible && (
                              <div>Total upvotes: {totalLikes}</div>
                            )}
                            {userLiked && (
                              <div>Great! Thanks for you feedback ❤️</div>
                            )} */}
                  </div>
                </>
              )}
            </>
          )
        }}
      </LikeButton>
    </div>
  )
}

export default Lyket
